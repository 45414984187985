import { type FC, type ReactNode } from 'react'
import Link, { type LinkProps } from 'next/link'

type Props = LinkProps & {
  href: string
  children: ReactNode
}

export const NextLink: FC<Props> = (props) => {
  return (
    <Link
      prefetch={false}
      {...props}
    >
      {props.children}
    </Link>
  )
}

NextLink.displayName = 'NextLink'
