import Repository from 'repositories/Repository'
import FirebaseManager from 'utils/FirebaseManager'
import Company from 'models/Company'

class CompanyRepository extends Repository {
  constructor() {
    super()

    this.collection = this.mainFirestore.collection(Company.COLLECTION_NAME)
  }

  find() {
    return FirebaseManager.getCompanyId()
      .then((companyId) => {
        return this.collection.doc(companyId).get({ source: 'server' })
      })
      .then((doc) => {
        if (!doc.exists) {
          // TODO: エラー返したほうがよいなら返すようにする
          return null
        }
        const company = new Company({ id: doc.id, ...doc.data() })
        localStorage.setItem('logoURL', company.logoURL)
        return company
      })
      .catch((error) => {
        throw error
      })
  }

  update(companyId, companyData) {
    return this.collection
      .doc(companyId)
      .update(companyData)
      .then(() => {
        return this.updateBuyerInfo(companyData)
      })
  }

  /**
   * Firebase storage へのアップロード処理
   * @param {アプロード対象のファイル} file
   */
  uploadLogo(file, companyId) {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop()
      if (!fileExtension) {
        reject(
          new Error(
            'ファイルの拡張子が取得できませんでした。開発者にお問い合わせください。',
          ),
        )
        return
      }

      const directoryPath = `companies/${companyId}/logoImage`
      const uploadTask = FirebaseManager.mainStorageRef
        .child(`${directoryPath}/logo.${fileExtension}`)
        .put(file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          reject(error)
          return
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              resolve(downloadURL)
              return
            })
            .catch((error) => {
              reject(error)
              return
            })
        },
      )
    })
  }
}

const companyRepository = new CompanyRepository()

Object.freeze(companyRepository)

export default companyRepository
