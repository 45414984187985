export const ChatMessageType = {
  NORMAL: 0,
  SCHEDULE_SELECTION: 1,
  FIXED_SCHEDULE: 2,
  FIRST_MESSAGE: 3,
  SYSTEM_SCHEDULE_SELECTION: 4,
  RESCHEDULE_MESSAGE: 5,
  MESSAGE_VIDEO_CHAT_FLOW: 6,
  INTEREST_ACTION: 7,
  INTEREST_ACTION_RESULT: 8,
  INTEREST_TO_ACE: 9,
  ADVICE_FOR_VIDEO_CHAT: 10,
  HELPFUL_ACE_CONTENT: 11,
  ADVICE_AFTER_MATCHING_FOR_ACE: 12,
  INTERESTED_THEME: 13,
  FILE_URL: 14,
} as const

export const Size = {
  HEADER_HEIGHT: 50,
  SIDE_BAR_WIDTH: 180,
  COMPANY_LOGO_HEIGHT: 100,
  COMPANY_LOGO_WIDTH: 100,
  PROFILE_IMAGE_HEIGHT: 50,
  PROFILE_IMAGE_WIDTH: 50,
}

export const DEFAULT_AVAILABLE_ACE_LIMIT = 3
export const REGISTRATION_ACE_LIMIT = 99
export const VIDEO_CHAT_LIMIT_SECONDS = 60 * 25
export const SEARCH_SKILL_LIMIT = 5

export const OfferListType = {
  BASE: 1,
  KEEP: 2,
  LIKE: 3,
  HIDDEN: 4,
  SWIPE: 5,
} as const

export const FIRESTORE_LIMIT_WHERE_IN = 10

export const SHORT_MIN_LENGTH = 400
export const SHORT_MAX_LENGTH = 3000
export const SHORT_RECOMMEND_LENGTH = 1200
export const PUBLISHED_SHORT_COUNT_LIMIT = 2
export const GIFT_OFFER_COUNT = 10
export const ACQUISITION_OFFER_COUNT_TYPE = {
  PLAN: 0,
  SHORT: 1,
}

export const MAX_UPLOAD_PROFILE_IMAGE_FILE_SIZE = 1 * 1024 * 1024

export const UPLOAD_PROFILE_IMAGE_RESIZE_WIDTH = 900

export const UPLOAD_PROFILE_IMAGE_THRESHOLD_LIMIT = 100000

export const UPLOAD_PROFILE_IMAGE_QUALITY = 0.9
