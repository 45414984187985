import type { FC } from 'react'
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { NextLink } from 'components/common/NextLink'
import { LINK_URLS } from 'constants/LinkURL'
import { Size } from 'constants/Number'
import iconExternalLink from 'images/legacy/external-link.svg?url'
import logo from 'images/legacy/onepair-logo.svg?url'
import iconQes from 'images/legacy/qes-circle.svg?url'

const styles = (theme: any) => ({
  appBar: {
    backgroundColor: theme.palette.grey[100],
    height: Size.HEADER_HEIGHT,
    zIndex: 1250, // Drawer(SideBar)がデフォルト 1200 なので, それを超える値を設定
  },
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const Logo = styled.img`
  width: 100%;
  max-height: 40px;
  padding: 0 1rem;
  cursor: pointer;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 0.5;
  }
`

const MenuList = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
`

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  margin-left: 24px;
  color: #000;
  cursor: pointer;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 0.5;
  }
`

const MenuIcon = styled.img`
  display: inline-block;
`

const MenuText = styled.div`
  margin-left: 7px;
  line-height: initial;
`

const HelpMenuText = styled(MenuText)`
  margin-left: 6px;
`

type Props = {
  siteTitle: string
  classes: any
}

const Header: FC<Props> = ({ siteTitle, classes }) => (
  <AppBar className={classes.appBar}>
    <Container>
      <NextLink href="/">
        <Logo
          src={logo.src}
          alt={siteTitle}
        />
      </NextLink>
      <MenuList>
        <MenuItem
          href={LINK_URLS.clientHelp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuIcon src={iconQes.src} />
          <HelpMenuText>使い方マニュアル</HelpMenuText>
        </MenuItem>
        <MenuItem
          href={`${process.env.NEXT_PUBLIC_ONEPAIR_WEB_URL}/offers`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuIcon src={iconExternalLink.src} />
          <MenuText>個別画面</MenuText>
        </MenuItem>
      </MenuList>
    </Container>
  </AppBar>
)

export default withStyles(styles, { withTheme: true })(Header)
// export default Header
