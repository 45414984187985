import React from 'react'
import {
  ArrowBack,
  Assignment,
  Business,
  Chat,
  FilterNone,
  History,
  Home,
  Mail,
  People,
  Person,
  Search,
  Star,
  SupervisedUserCircle,
} from '@material-ui/icons'

export const ICONS = {
  arrowBack: ArrowBack,
  assignment: Assignment,
  business: Business,
  chat: Chat,
  history: History,
  home: Home,
  mail: Mail,
  person: Person,
  search: Search,
  user: SupervisedUserCircle,
  people: People,
  filterNone: FilterNone,
  star: Star,
}

const MaterialIcon = ({ iconName, color }) => {
  const Icon = ICONS[iconName]
  return Icon ? <Icon style={{ color: color }} /> : <React.Fragment />
}

export default MaterialIcon
