import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { RecoilEnv, RecoilRoot } from 'recoil'
import { Analytics } from '@vercel/analytics/react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'
import { Auth } from 'components/Auth'
import { HeadContents } from 'components/HeadContents'
import GlobalStyle from 'styles/GlobalStyle'
import 'styles/globals.css'
import 'styles/reset.css'
import 'styles/base.css'
import 'rc-calendar/assets/index.css'
import 'styles/components/Calendar.css'
import 'styles/components/Toastify.css'
import 'styles/components/lexical.css'
import 'styles/components/react_dates_overrides.css'
import theme from 'styles/Theme'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false

const App: NextPage<AppProps> = ({ Component, pageProps }) => (
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    <RecoilRoot>
      <HeadContents />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div id="root">
          <Auth layout={pageProps.layout}>
            <Component {...pageProps} />
          </Auth>
          <Analytics />
          <ToastContainer theme="colored" />
        </div>
      </ThemeProvider>
    </RecoilRoot>
  </SWRConfig>
)

export default App
