export const COMPANY_SCALES = [
  { name: '特にこだわりはない', value: 'noInterest' },
  { name: 'スタートアップ', value: 'startup' },
  { name: 'ミドルベンチャー', value: 'middleVenture' },
  { name: 'メガベンチャー', value: 'megaVenture' },
  { name: '中小企業', value: 'sme' },
  { name: '大企業', value: 'largeCompany' },
  { name: 'プロフェッショナルファーム', value: 'professionalFarm' },
] as const

export const ENGLISH_LEVELS = [
  { name: '日常会話レベル', value: 'daily' },
  { name: 'ビジネス会話レベル', value: 'business' },
  { name: 'ネイティブレベル', value: 'native' },
]

export const GENDERS = [
  { name: '男性', value: 'male' },
  { name: '女性', value: 'female' },
  { name: 'その他', value: 'other' },
]

export const INDUSTRIES = [
  { name: 'IT・インターネット', value: 'internet' },
  { name: 'メーカー', value: 'maker' },
  { name: '金融', value: 'finance' },
  { name: 'コンサルティング', value: 'consulting' },
  {
    name: '商社・流通・小売・サービス',
    value: 'tradingDistributionRetailService',
  },
  { name: '不動産・建設', value: 'realEstateBuilding' },
  {
    name: '広告・マスコミ・エンタメ',
    value: 'advertisementMassCommunicationEntertainment',
  },
  { name: '医薬・医療・メディカル', value: 'medical' },
  { name: 'インフラ・公共・その他', value: 'infrastructurePublicWorkOther' },
] as const

export const JOB_CHANGE_WILLINGNESSES = [
  { name: 'すでに転職活動をしている', value: 'alreadyStartActivity' },
  { name: '転職を検討している', value: 'considering' },
  { name: 'いい話があれば検討したい', value: 'ifGoodStory' },
  { name: '直近の転職は考えていない', value: 'noIntention' },
] as const

export const SIDE_JOB_WILLINGNESSES = [
  { name: '積極的に探している', value: 'active' },
  { name: 'いい話があれば検討したい', value: 'ifGoodStory' },
  { name: '直近の副業は考えていない', value: 'noIntention' },
] as const

export const NUM_OF_MEMBERS = [
  { name: '1〜20人', value: 'oneToTwenty' },
  { name: '21〜50人', value: 'twentyToFifty' },
  { name: '51〜100人', value: 'fiftyToOneHundred' },
  { name: '101〜300人', value: 'oneHundredToThreeHundred' },
  { name: '301〜500人', value: 'threeHundredToFiveHundred' },
  { name: 'それ以上', value: 'over' },
]

export const OCCUPATIONS = [
  { name: 'エンジニア', value: 'engineer' },
  { name: 'デザイナー', value: 'designer' },
  { name: '経営企画・経営戦略', value: 'planner' },
  { name: 'コンサルタント', value: 'consultant' },
  { name: 'PM・Webディレクター', value: 'director' },
  { name: 'セールス・企画', value: 'sales' },
  { name: 'マーケター・PR', value: 'marketer' },
  { name: 'コーポレート・管理', value: 'corporateStaff' },
  { name: '専門職・その他', value: 'other' },
] as const

export const SUB_OCCUPATIONS = [
  {
    name: 'フロントエンドエンジニア',
    value: 'frontendEngineer',
    category: 'engineer',
  },
  {
    name: 'モバイルアプリエンジニア',
    value: 'mobileAppEngineer',
    category: 'engineer',
  },
  {
    name: 'サーバサイドエンジニア',
    value: 'serversideEngineer',
    category: 'engineer',
  },
  { name: 'インフラエンジニア', value: 'infraEngineer', category: 'engineer' },
  {
    name: 'QAエンジニア',
    value: 'qualityAssuranceEngineer',
    category: 'engineer',
  },
  {
    name: '情報システム・社内SE',
    value: 'informationSystemEngineer',
    category: 'engineer',
  },
  {
    name: '保守・運用・監視・テクニカルサポート',
    value: 'maintenanceEngineer',
    category: 'engineer',
  },
  {
    name: 'プリセールス・セールスエンジニア',
    value: 'salesEngineer',
    category: 'engineer',
  },
  {
    name: 'データサイエンティスト',
    value: 'dataScientist',
    category: 'engineer',
  },
  {
    name: '製品エンジニア（ハードウェア・ソフトウェア）',
    value: 'productEngineer',
    category: 'engineer',
  },
  { name: 'その他エンジニア系', value: 'otherEngineer', category: 'engineer' },

  { name: 'Web・UI/UXデザイナー', value: 'webDesigner', category: 'designer' },
  { name: 'アートディレクター', value: 'artDirector', category: 'designer' },
  { name: '工業デザイナー', value: 'industrialDesigner', category: 'designer' },
  {
    name: 'インテリアデザイナー',
    value: 'interiorDesigner',
    category: 'designer',
  },
  {
    name: 'ファッションデザイナー',
    value: 'fashionDesigner',
    category: 'designer',
  },
  { name: 'その他デザイナー系', value: 'otherDesigner', category: 'designer' },

  {
    name: '経営者・CEO・COO・カントリーヘッド等',
    value: 'headPlanner',
    category: 'planner',
  },
  {
    name: 'CTO・CIO',
    value: 'ctoCio',
    category: 'planner',
  },
  { name: 'CFO', value: 'cfo', category: 'planner' },
  {
    name: '経営企画・経営戦略',
    value: 'managementPlanner',
    category: 'planner',
  },
  { name: '事業企画・事業統括', value: 'businessPlanner', category: 'planner' },
  { name: 'M&A・合併・連携', value: 'mAndA', category: 'planner' },
  { name: '新規事業', value: 'newBusinessPlanner', category: 'planner' },
  {
    name: 'その他、経営・経営企画・事業企画系',
    value: 'otherPlanner',
    category: 'planner',
  },

  {
    name: '戦略コンサルタント',
    value: 'strategyConsultant',
    category: 'consultant',
  },
  {
    name: '財務・会計コンサルタント',
    value: 'financeConsultant',
    category: 'consultant',
  },
  {
    name: '組織・人事コンサルタント',
    value: 'organizationConsultant',
    category: 'consultant',
  },
  { name: 'ITコンサルタント', value: 'itConsultant', category: 'consultant' },
  {
    name: 'マーケティングコンサルタント',
    value: 'marketingConsultant',
    category: 'consultant',
  },
  {
    name: 'リサーチャー・調査員',
    value: 'researcher',
    category: 'consultant',
  },
  {
    name: 'その他、コンサルタント系',
    value: 'otherConsultant',
    category: 'consultant',
  },

  {
    name: 'プロダクトマネージャー',
    value: 'productManager',
    category: 'director',
  },
  {
    name: 'プロジェクトマネージャー',
    value: 'projectDirector',
    category: 'director',
  },
  {
    name: 'Webプロデューサー・ディレクター',
    value: 'webProducerDirector',
    category: 'director',
  },
  {
    name: 'Webコンテンツ企画・編集・ライティング',
    value: 'webServiceContentsDirector',
    category: 'director',
  },
  {
    name: 'ECサイト運営・ECコンサルタント',
    value: 'ecManagement',
    category: 'director',
  },
  {
    name: 'その他ディレクター系',
    value: 'otherDirector',
    category: 'director',
  },

  { name: '営業 (個人向け)', value: 'individualSales', category: 'sales' },
  { name: '営業 (法人向け)', value: 'corporateSales', category: 'sales' },
  { name: '営業企画', value: 'salesPlanning', category: 'sales' },
  {
    name: '営業事務・アシスタント',
    value: 'salesOfficeWork',
    category: 'sales',
  },
  { name: '代理店営業・アライアンス', value: 'agencySales', category: 'sales' },
  {
    name: 'インサイドセールス・内勤営業',
    value: 'insideSales',
    category: 'sales',
  },
  { name: '海外営業', value: 'overseasSales', category: 'sales' },
  {
    name: 'キャリアコンサルタント・キャリアカウンセラー',
    value: 'careerConsultant',
    category: 'sales',
  },
  { name: 'カスタマーサクセス', value: 'customerSuccess', category: 'sales' },
  {
    name: 'カスタマーサポート・ヘルプデスク',
    value: 'customerSupport',
    category: 'sales',
  },
  {
    name: 'コールセンター管理・運営（SV）',
    value: 'callCenterManagement',
    category: 'sales',
  },
  { name: '店舗・FC開発', value: 'storeDevelopment', category: 'sales' },
  {
    name: '店長・店舗管理・店舗運営',
    value: 'storeManagement',
    category: 'sales',
  },
  { name: 'その他営業系', value: 'otherSales', category: 'sales' },

  {
    name: '商品企画・開発',
    value: 'serviceDevelopmentMarketer',
    category: 'marketer',
  },
  {
    name: '販促',
    value: 'promotionMarketer',
    category: 'marketer',
  },
  {
    name: 'プロデューサー・ディレクター',
    value: 'producerDirector',
    category: 'marketer',
  },
  {
    name: 'Web広告運用・SEO',
    value: 'webAdvertisingOperation',
    category: 'marketer',
  },
  {
    name: 'CRM',
    value: 'crm',
    category: 'marketer',
  },
  {
    name: '広報・PR・広告宣伝',
    value: 'pr',
    category: 'marketer',
  },
  {
    name: 'リサーチ・データ分析',
    value: 'research',
    category: 'marketer',
  },
  {
    name: 'MD・VMD',
    value: 'mdVmd',
    category: 'marketer',
  },
  {
    name: '仕入れ・バイヤー',
    value: 'purchase',
    category: 'marketer',
  },
  {
    name: 'その他マーケティング系',
    value: 'otherMarketer',
    category: 'marketer',
  },

  {
    name: '採用',
    value: 'adoption',
    category: 'corporateStaff',
  },
  {
    name: '人材開発・人材育成・研修',
    value: 'humanResources',
    category: 'corporateStaff',
  },
  {
    name: '制度企画・組織開発',
    value: 'systemPlanning',
    category: 'corporateStaff',
  },
  {
    name: '労務・給与',
    value: 'laborSalary',
    category: 'corporateStaff',
  },
  {
    name: '財務・会計・経理',
    value: 'financeStaff',
    category: 'corporateStaff',
  },
  {
    name: '法務・コンプライアンス',
    value: 'legalStaff',
    category: 'corporateStaff',
  },
  { name: 'IR', value: 'irStaff', category: 'corporateStaff' },
  {
    name: '内部監査・内部統制',
    value: 'internalAudit',
    category: 'corporateStaff',
  },
  { name: '総務', value: 'generalAffairs', category: 'corporateStaff' },
  { name: '秘書', value: 'secretary', category: 'corporateStaff' },
  {
    name: '物流企画・物流管理',
    value: 'logisticsManagement',
    category: 'corporateStaff',
  },
  {
    name: '商品・在庫管理',
    value: 'merchandiseManagement',
    category: 'corporateStaff',
  },
  { name: 'その他管理部門系', value: 'otherStaff', category: 'corporateStaff' },

  { name: '記者・ライター', value: 'writer', category: 'other' },
  { name: '税理士', value: 'taxAccountant', category: 'other' },
  { name: '弁護士', value: 'lawyer', category: 'other' },
  {
    name: '弁理士・知的管理',
    value: 'intelligentManagement',
    category: 'other',
  },
  { name: '研究職', value: 'labwork', category: 'other' },
  { name: '公務員', value: 'civilServant', category: 'other' },
  { name: 'その他', value: 'other', category: 'other' },
] as const

export const PREFECTURES = [
  { name: '北海道', value: 1 },
  { name: '青森県', value: 2 },
  { name: '岩手県', value: 3 },
  { name: '宮城県', value: 4 },
  { name: '秋田県', value: 5 },
  { name: '山形県', value: 6 },
  { name: '福島県', value: 7 },
  { name: '茨城県', value: 8 },
  { name: '栃木県', value: 9 },
  { name: '群馬県', value: 10 },
  { name: '埼玉県', value: 11 },
  { name: '千葉県', value: 12 },
  { name: '東京都', value: 13 },
  { name: '神奈川県', value: 14 },
  { name: '新潟県', value: 15 },
  { name: '富山県', value: 16 },
  { name: '石川県', value: 17 },
  { name: '福井県', value: 18 },
  { name: '山梨県', value: 19 },
  { name: '長野県', value: 20 },
  { name: '岐阜県', value: 21 },
  { name: '静岡県', value: 22 },
  { name: '愛知県', value: 23 },
  { name: '三重県', value: 24 },
  { name: '滋賀県', value: 25 },
  { name: '京都府', value: 26 },
  { name: '大阪府', value: 27 },
  { name: '兵庫県', value: 28 },
  { name: '奈良県', value: 29 },
  { name: '和歌山県', value: 30 },
  { name: '鳥取県', value: 31 },
  { name: '島根県', value: 32 },
  { name: '岡山県', value: 33 },
  { name: '広島県', value: 34 },
  { name: '山口県', value: 35 },
  { name: '徳島県', value: 36 },
  { name: '香川県', value: 37 },
  { name: '愛媛県', value: 38 },
  { name: '高知県', value: 39 },
  { name: '福岡県', value: 40 },
  { name: '佐賀県', value: 41 },
  { name: '長崎県', value: 42 },
  { name: '熊本県', value: 43 },
  { name: '大分県', value: 44 },
  { name: '宮崎県', value: 45 },
  { name: '鹿児島県', value: 46 },
  { name: '沖縄県', value: 47 },
  { name: '海外', value: 48 },
] as const

export const MANAGEMENT_EXPERIENCES = [
  { name: 'なし', value: 'none' },
  { name: 'あり (1〜5人) ', value: 'small' },
  { name: 'あり (6〜10人) ', value: 'middle' },
  { name: 'あり (11〜20人) ', value: 'large' },
  { name: 'あり (21人〜 ) ', value: 'more' },
]

export const FOCUS_ON_CHOOSING_COMPANIES = [
  { name: 'ビジョン・理念への共感', value: 'visionPhilosophy' },
  { name: 'カルチャーマッチ', value: 'cultureMatch' },
  { name: '上司・同僚との働きやすさ', value: 'workingFriendlyWithMembers' },
  { name: '会社・事業の成長性', value: 'growthPotentialOfCompany' },
  { name: '事業内容に関心が持てるか', value: 'interestedInBusiness' },
  { name: '業務内容に関心が持てるか', value: 'interestedInBusinessContent' },
  { name: '年収・福利厚生などの待遇', value: 'treatment' },
]

export const WORK_STYLES = [
  { name: '自社サービスに携われる', value: 'ownedService' },
  { name: 'グローバルな環境で働ける', value: 'global' },
  { name: '新規事業に携われる', value: 'newBusiness' },
  { name: '大きな裁量を持てる', value: 'discresion' },
  { name: 'マネジメントできる', value: 'management' },
  { name: '経験・スキルを活かせる', value: 'experienceAndSkill' },
  { name: '専門性を高められる', value: 'expert' },
  { name: '様々な職種・職務に挑戦できる', value: 'variousDuties' },
  { name: 'ワーク・ライフ・バランスを大事にできる', value: 'workLifeBalance' },
  { name: 'フルリモート・フレックス勤務可能', value: 'remoteFlex' },
]

export const AUTHORITIES = [
  { name: '管理者', value: 'admin' },
  { name: 'メンバー', value: 'member' },
]

export const INTEREST_ACTIONS = [
  { name: 'まずは気軽に話してみたい', value: 'interestTalkingCasually' },
  { name: '転職先として興味あり', value: 'interestConsideringChange' },
  { name: '応募を考えている', value: 'interestEntering' },
]

export const EMPLOYMENT_FORMS = [
  { name: '正社員 / 中途', value: 'midCareer' },
  { name: '副業・委託', value: 'sideJob' },
]

export const SIDE_JOB_WORKING_STYLES = [
  { name: '一部リモート可', value: 'partiallyRemote' },
  { name: 'リモートメイン', value: 'mainRemote' },
  { name: 'フルリモート可', value: 'fullRemote' },
  { name: '週10時間以上', value: 'overTenHoursWeekly' },
  { name: '週20時間以上', value: 'overTwentyHoursWeekly' },
  { name: '週30時間以上', value: 'overThirtyHoursWeekly' },
]

export const MID_CAREER_WORKING_STYLES = [
  { name: '一部リモート可', value: 'partiallyRemote' },
  { name: 'リモートメイン', value: 'mainRemote' },
  { name: 'フルリモート可', value: 'fullRemote' },
  { name: '時短勤務可', value: 'shortTime' },
]

export const PREFERRED_WORKPLACES = [
  { name: '特にこだわりはない', value: 'none', default: true },
  { name: '東京都内', value: 'tokyo', default: true },
  { name: '東京以外の都市', value: 'exceptTokyo', default: true },
  { name: '海外', value: 'abroad', default: true },
  { name: 'リモート勤務', value: 'remote', default: true },

  { name: '北海道', value: 'hokkaido', default: false },
  { name: '青森県', value: 'aomori', default: false },
  { name: '岩手県', value: 'iwate', default: false },
  { name: '宮城県', value: 'miyagi', default: false },
  { name: '秋田県', value: 'akita', default: false },
  { name: '山形県', value: 'yamagata', default: false },
  { name: '福島県', value: 'fukushima', default: false },
  { name: '茨城県', value: 'ibaraki', default: false },
  { name: '栃木県', value: 'tochigi', default: false },
  { name: '群馬県', value: 'gunma', default: false },
  { name: '埼玉県', value: 'saitama', default: false },
  { name: '千葉県', value: 'chiba', default: false },
  { name: '神奈川県', value: 'kanagawa', default: false },
  { name: '新潟県', value: 'niigata', default: false },
  { name: '富山県', value: 'toyama', default: false },
  { name: '石川県', value: 'ishikawa', default: false },
  { name: '福井県', value: 'fukui', default: false },
  { name: '山梨県', value: 'yamanashi', default: false },
  { name: '長野県', value: 'nagano', default: false },
  { name: '岐阜県', value: 'gifu', default: false },
  { name: '静岡県', value: 'shizuoka', default: false },
  { name: '愛知県', value: 'aichi', default: false },
  { name: '三重県', value: 'mie', default: false },
  { name: '滋賀県', value: 'shiga', default: false },
  { name: '京都府', value: 'kyoto', default: false },
  { name: '大阪府', value: 'osaka', default: false },
  { name: '兵庫県', value: 'hyogo', default: false },
  { name: '奈良県', value: 'nara', default: false },
  { name: '和歌山県', value: 'wakayama', default: false },
  { name: '鳥取県', value: 'tottori', default: false },
  { name: '島根県', value: 'shimane', default: false },
  { name: '岡山県', value: 'okayama', default: false },
  { name: '広島県', value: 'hiroshima', default: false },
  { name: '山口県', value: 'yamaguchi', default: false },
  { name: '徳島県', value: 'tokushima', default: false },
  { name: '香川県', value: 'kagawa', default: false },
  { name: '愛媛県', value: 'ehime', default: false },
  { name: '高知県', value: 'kochi', default: false },
  { name: '福岡県', value: 'fukuoka', default: false },
  { name: '佐賀県', value: 'saga', default: false },
  { name: '長崎県', value: 'nagasaki', default: false },
  { name: '熊本県', value: 'kumamoto', default: false },
  { name: '大分県', value: 'oita', default: false },
  { name: '宮崎県', value: 'miyazaki', default: false },
  { name: '鹿児島県', value: 'kagoshima', default: false },
  { name: '沖縄県', value: 'okinawa', default: false },
]

export const PREFECTURE_AREA_LIST = [
  {
    name: '北海道・東北',
    list: [
      'hokkaido',
      'aomori',
      'iwate',
      'miyagi',
      'akita',
      'yamagata',
      'fukushima',
    ],
  },
  {
    name: '関東',
    list: [
      'ibaraki',
      'tochigi',
      'gunma',
      'saitama',
      'chiba',
      'tokyo',
      'kanagawa',
    ],
  },
  {
    name: '中部',
    list: [
      'niigata',
      'toyama',
      'ishikawa',
      'fukui',
      'yamanashi',
      'nagano',
      'gifu',
      'shizuoka',
      'aichi',
    ],
  },
  {
    name: '関西',
    list: [
      'mie',
      'shiga',
      'kyoto',
      'osaka',
      'osaka',
      'hyogo',
      'nara',
      'wakayama',
    ],
  },
  {
    name: '中国・四国',
    list: [
      'tottori',
      'shimane',
      'okayama',
      'hiroshima',
      'hiroshima',
      'tokushima',
      'kagawa',
      'ehime',
      'kochi',
    ],
  },
  {
    name: '九州・沖縄',
    list: [
      'fukuoka',
      'saga',
      'nagasaki',
      'kumamoto',
      'oita',
      'miyazaki',
      'kagoshima',
      'okinawa',
    ],
  },
]

export const OFFER_SORT_TYPES = [
  {
    id: 'recommended',
    title: 'おすすめ順',
  },
  {
    id: 'recentLogin',
    title: 'ログイン日時順',
  },
] as const
