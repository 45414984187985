export const TOP = '/' as const
export const COMPANY = '/company' as const
export const ACES = '/aces' as const
export const RECRUITMENTS = '/recruitments' as const
export const INTERVIEW_HISTORIES = '/interview-histories' as const
export const USERS = '/users' as const
export const CREATE_USER = '/users/create' as const
export const MATCHINGS = '/matchings' as const
export const MESSAGE_CHAT = '/message-chat' as const
export const OFFERS = '/offers' as const
export const SHORTS = '/shorts' as const
// 管理会社用
export const OPERATOR_SEND_MESSAGE = '/operator/send-message' as const

export const PAGE_TITLES = {
  login: 'ログイン',
  'forgot-password': 'パスワード確認',
  'reset-password': 'パスワードリセット',
  top: 'ダッシュボード',
  company: '会社情報',
  aces: 'エース一覧',
  recruitments: '募集ポジション一覧',
  'interview-histories': '面談履歴',
  create: '作成',
  edit: '編集',
  users: 'メンバー設定',
  matchings: 'マッチング一覧',
  'message-chat': 'メッセージ',
  offers: 'オファー送付',
  shorts: 'ショート',
  // 管理会社用
  'operator-send-message': '運営 - メッセージ送付',
} satisfies Record<string, string>
