import { atom, selector } from 'recoil'

export const crtUserState = atom({
  key: 'auth/crtUser',
  default: false,
})

export const mainUserState = atom({
  key: 'auth/mainUser',
  default: false,
})

export const isAuthSelector = selector({
  key: 'auth/isAuth',
  get: ({ get }) => get(crtUserState) && get(mainUserState),
})
