import { type FC, type ReactElement, memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  crtUserState,
  isAuthSelector,
  mainUserState,
} from 'globalStates/atoms/authAtom'
import { companyState } from 'globalStates/atoms/companyAtom'
import Header from 'components/common/Header'
import Layout from 'components/common/Layout'
import SideBar from 'components/common/SideBar'
import CompanyRepository from 'repositories/CompanyRepository'
import ChannelService from 'utils/ChannelService'
import FirebaseManager from 'utils/FirebaseManager'
import {
  KEY_INFORMAL_DECISION_CREATE_JOKER_ID,
  KEY_INTERVIEW_HISTORIES_MESSAGE_CHAT_ID,
  KEY_RECRUITMENTS_COPY_RECRUITMENT_ID,
  KEY_SELECTED_MATCHING_ID,
} from 'constants/LocalStorage'

const GUEST_PAGE_PATHS = ['/login', '/reset-password', '/forgot-password']

type LayoutProps = {
  readonly plain?: boolean
  readonly breadcrumb?: boolean
  readonly footer?: boolean
}

type Props = {
  children: ReactElement
  layout?: LayoutProps
}

const initialLayout = {
  plain: false,
  breadcrumb: true,
  footer: true,
}

export const Auth: FC<Props> = memo(({ children, layout = initialLayout }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [isBootedChannelIO, setIsBootedChannelIO] = useState(false)
  const router = useRouter()
  const setCrtUser = useSetRecoilState(crtUserState)
  const setMainUser = useSetRecoilState(mainUserState)
  const setCompany = useSetRecoilState(companyState)
  const isAuth = useRecoilValue(isAuthSelector)

  const isGuestPage = GUEST_PAGE_PATHS.includes(router.pathname)

  const deleteAllLocalStorage = () => {
    localStorage.removeItem('cid')
    localStorage.removeItem('logoURL')
    localStorage.removeItem('isSignIn')
    localStorage.removeItem(KEY_RECRUITMENTS_COPY_RECRUITMENT_ID)
    localStorage.removeItem(KEY_INTERVIEW_HISTORIES_MESSAGE_CHAT_ID)
    localStorage.removeItem(KEY_INFORMAL_DECISION_CREATE_JOKER_ID)
    localStorage.removeItem(KEY_SELECTED_MATCHING_ID)
  }

  const initFirebaseCrtAuth = () =>
    new Promise<void>((resolve) => {
      FirebaseManager.crtAuth.onAuthStateChanged(async (crtUser) => {
        if (!crtUser) {
          resolve()
          return
        }
        const company = await CompanyRepository.find()

        setCrtUser(true)
        setCompany(company)

        if (
          crtUser &&
          !isBootedChannelIO &&
          process.env.NEXT_PUBLIC_CHANNEL_IO_PLUGIN_KEY
        ) {
          ChannelService.boot({
            pluginKey: process.env.NEXT_PUBLIC_CHANNEL_IO_PLUGIN_KEY,
            memberId: crtUser.uid,
            profile: { companyId: company?.id },
          })
          setIsBootedChannelIO(true)
        } else if (!crtUser && isBootedChannelIO) {
          // ChannelService.shutdown()
          setIsBootedChannelIO(false)
        }

        resolve()
      })
    })

  const initFirebaseMainAuth = () =>
    new Promise<void>((resolve) => {
      FirebaseManager.mainAuth.onAuthStateChanged((mainUser) => {
        setMainUser(true)
        resolve()
      })
    })

  const init = async () => {
    await Promise.all([initFirebaseCrtAuth(), initFirebaseMainAuth()])
    setIsAuthLoading(false)
  }

  useEffect(() => {
    void init()
  }, [])

  useEffect(() => {
    // 読み込み中は終了
    if (isAuthLoading) {
      return
    }

    // ログイン中は終了
    if (isAuth) {
      return
    }

    const isSignIn = localStorage.getItem('isSignIn')
    if (isSignIn === 'true') {
      localStorage.setItem('sessionError', 'Session timeout')
    }

    // 未認証の場合はローカルデータをすべて削除
    deleteAllLocalStorage()

    // アクセスページがゲスト用ページ以外の場合はログインページに遷移
    if (!isGuestPage) {
      router.push('/login')
    }
  }, [isAuthLoading])

  if (isAuthLoading) {
    return (
      <CircularProgress
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      />
    )
  }

  if (!isAuth) {
    if (!isGuestPage) {
      return null
    }
    return children
  }

  if (layout.plain) {
    return children
  }

  return (
    <>
      <Header siteTitle="LEAPLACE管理画面" />
      <SideBar />
      <Layout
        isShowBreadcrumb={layout.breadcrumb}
        isShowFooter={layout.footer}
      >
        {children}
      </Layout>
    </>
  )
})

Auth.displayName = 'Auth'
