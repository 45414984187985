import React from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { getBreadcrumbItems } from 'utils/BreadcrumbUtil'

const Content = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const Triangle = styled.svg`
  width: 10px;
  height: 10px;
  padding: 0 10px;
`

const Title = styled.span`
  ${(props) =>
    !props.isLastItem &&
    css`
      cursor: pointer;
      transition: opacity 0.2s ease-in;

      :hover {
        opacity: 0.5;
      }
    `}
`

const Breadcrumb = () => {
  const router = useRouter()
  const breadcrumbItems = getBreadcrumbItems(window.location.pathname)

  return (
    <Content>
      {breadcrumbItems &&
        breadcrumbItems.map((item, index) => {
          const isFirstItem = index === 0
          const isLastItem = index + 1 === breadcrumbItems.length

          return (
            <React.Fragment key={index}>
              {!isFirstItem && (
                <Triangle>
                  <polygon
                    points="0,0 0,10 10,5"
                    stroke="#d8d8d8"
                    fill="#d8d8d8"
                  />
                </Triangle>
              )}
              <Title
                isLastItem={isLastItem}
                onClick={(_) => !isLastItem && router.push(item.route)}
              >
                {item.title}
              </Title>
            </React.Fragment>
          )
        })}
    </Content>
  )
}

export default Breadcrumb
