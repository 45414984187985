import React from 'react'
import styled from 'styled-components'
import { LINK_URLS } from 'constants/LinkURL'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  font-size: 0.75rem;
`

const LinkContainer = styled.div`
  position: absolute;
  right: 20px;
`

const Link = styled.a`
  margin: 0 5px;
  color: #4a4a4a;

  :hover {
    opacity: 0.7;
  }
`

const PageFooter = () => (
  <Container>
    <span>COPYRIGHT ©REHATCH Inc.</span>
    <LinkContainer>
      <Link
        href={LINK_URLS.contact}
        target="_blank"
      >
        お問い合わせ
      </Link>
      <Link
        href={LINK_URLS.aboutCompany}
        target="_blank"
      >
        運営会社
      </Link>
      <Link
        href={LINK_URLS.termsOfService}
        target="_blank"
      >
        利用規約
      </Link>
      <Link
        href={LINK_URLS.privacyPolicy}
        target="_blank"
      >
        プライバシーポリシー
      </Link>
    </LinkContainer>
  </Container>
)

export default PageFooter
