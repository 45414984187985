import styled from 'styled-components'

const StyledDivider = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 1px;
  margin: 10px 0;
  background-color: ${(props) => props.color || '#d7d7d7'};
`

export default StyledDivider
