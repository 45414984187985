import { atom, selector } from 'recoil'
import { OFFER_SORT_TYPES } from 'constants/FieldValue'
import { OfferListType } from 'constants/Number'
import type Ace from 'models/Ace'
import type Joker from 'models/Joker'
import type JokerCondition from 'models/JokerCondition'
import type Recruitment from 'models/Recruitment'
import type { OfferSortType } from 'types'

export const acesState = atom<Ace[]>({
  key: 'offer/ace/aces',
  default: [],
})

export const selectedAceIdState = atom<Ace['id']>({
  key: 'offer/ace/selected',
  default: '',
})

export const selectedAceSelectorState = selector<Ace | null>({
  key: 'offer/selectedAceSelector',
  get: ({ get }) => {
    const aces = get(acesState)
    const selectedAceId = get(selectedAceIdState)
    return aces.find((ace) => ace.id === selectedAceId) || null
  },
})

export const listTypeState = atom<
  (typeof OfferListType)[keyof typeof OfferListType]
>({
  key: 'offer/listType',
  default: OfferListType.BASE,
})

export const sortTypeState = atom<OfferSortType>({
  key: 'offer/sortType',
  default: OFFER_SORT_TYPES[0].id,
})

export const jokerConditionState = atom<Omit<JokerCondition, 'toJS'>>({
  key: 'offer/jokerCondition',
  default: {
    jobChangeWillingnesses: [],
    sideJobWillingnesses: [],
    maxAge: null,
    minAge: null,
    industries: [],
    occupations: [],
    subOccupations: [],
    preferredWorkplaces: [],
    companyScales: [],
    exceptOffered: false,
    freeWords: [],
  },
})

export const updateJokerConditionState = atom<Omit<JokerCondition, 'toJS'>>({
  key: 'offer/updateJokerCondition',
  default: {
    jobChangeWillingnesses: [],
    sideJobWillingnesses: [],
    maxAge: null,
    minAge: null,
    industries: [],
    occupations: [],
    subOccupations: [],
    preferredWorkplaces: [],
    companyScales: [],
    exceptOffered: false,
    freeWords: [],
  },
})

export const naviContainerHeight = atom<number>({
  key: 'offer/naviContainerHeight',
  default: 0,
})

export const skillNamesState = atom<string[]>({
  key: 'offer/skillNames',
  default: [],
})

export const recruitmentState = atom<Recruitment[]>({
  key: 'offer/recruitmentWithAce',
  default: [],
})

export const selectedJokerState = atom<Joker | null>({
  key: 'offer/selectedJoker',
  default: null,
})

export const isOpenJokerDetailState = atom<boolean>({
  key: 'offer/isOpenJokerDetail',
  default: false,
})

/**
 * SWRの一時停止フラグ
 */
export const isPausedFetchBaseState = atom<boolean>({
  key: 'offer/isPausedFetchBase',
  default: false,
})

export const isPausedFetchKeepState = atom<boolean>({
  key: 'offer/isPausedFetchKeep',
  default: false,
})

export const isPausedFetchHiddenState = atom<boolean>({
  key: 'offer/isPausedFetchHidden',
  default: false,
})

export const isPausedFetchSwipedState = atom<boolean>({
  key: 'offer/isPausedFetchSwiped',
  default: false,
})

export const isOpenOfferListItemState = atom<boolean>({
  key: 'offer/isOpenOfferListItem',
  default: true,
})

export const isDoneGetLocalStorageState = atom<boolean>({
  key: 'offer/isDoneGetLocalStorageState',
  default: false,
})
