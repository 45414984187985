export const KEY_RECRUITMENTS_COPY_RECRUITMENT_ID =
  'recruitments:copy:recruitmentId'
/* 
  FIXME:  issue#295 このタスク以降使用しなくなったが
          キャッシュが残っているユーザーのことも考慮してしばらく残しておく
*/
export const KEY_INTERVIEW_HISTORIES_MESSAGE_CHAT_ID =
  'interviewHistories:messageChat:matchingId'

export const KEY_SELECTED_MATCHING_ID = 'selectedMatchingId'

export const KEY_INFORMAL_DECISION_CREATE_JOKER_ID =
  'informalDecision:create:jokerId'

export const KEY_ACE_PERSONALITY_QUESTION = 'acePersonalityQuestion'
export const KEY_JOKER_PERSONALITY_QUESTION = 'jokerPersonalityQuestion'

export const CHAT_MESSAGE_FILE_URL = 'chatMessageFileURL'
