import { isEmpty } from 'lodash'
import * as ROUTE from 'constants/Route'
import BreadcrumbItem from 'models/BreadcrumbItem'

export const getBreadcrumbItems = (pathname) => {
  let paths = pathname.split('/')
  const result = []
  if (pathname === '/' || paths.length < 2) {
    return [
      new BreadcrumbItem({ title: ROUTE.PAGE_TITLES['top'], route: ROUTE.TOP }),
    ]
  }

  // 1つ目は余計(空文字)なので切る
  paths = paths.slice(1)

  // そのページの大元のパスを取得
  const firstPathRoute = `/${paths[0]}`
  paths.map((path) => {
    let title = ROUTE.PAGE_TITLES[path]

    // ページのタイトルに該当しない (エースIDや募集ポジションIDなどの) パスは一律「詳細」とする
    if (isEmpty(title)) {
      if (
        firstPathRoute === ROUTE.ACES ||
        firstPathRoute === ROUTE.RECRUITMENTS ||
        firstPathRoute === ROUTE.INTERVIEW_HISTORIES ||
        firstPathRoute === ROUTE.USERS ||
        firstPathRoute === ROUTE.MATCHINGS
      ) {
        title = '詳細'
      } else {
        title = ' - '
      }
    }

    const prevFullPath =
      result.length > 0 ? result[result.length - 1].route : ''
    const item = new BreadcrumbItem({
      title: title,
      route: `${prevFullPath}/${path}`,
    })
    result.push(item)
  })

  return result
}
