import { createGlobalStyle } from 'styled-components'

/**
 * グローバルスタイル
 *
 * @remarks
 * クラスセレクタ(ex. .container) のみ追加してください。
 * タイプセレクタ(ex. div) を追加したい場合、styles/base.css に追加してください。
 * (CSS @layer を使用しないと、コンポーネントで指定する css({}) よりも、タイプセレクタの方が優先されてしまうため)
 */
const GlobalStyle = createGlobalStyle`
  /* stylelint-disable-next-line no-empty-source */
`

export default GlobalStyle
