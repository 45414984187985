import type { FC, ReactElement } from 'react'
import styled from 'styled-components'
import Breadcrumb from 'components/common/Breadcrumb'
import PageFooter from 'components/PageFooter'
import { Size } from 'constants/Number'

type Props = {
  children: ReactElement
  isShowBreadcrumb?: boolean
  isShowFooter?: boolean
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${Size.SIDE_BAR_WIDTH}px - 40px);
  min-height: calc(
    100% - ${Size.HEADER_HEIGHT}px - 40px
  ); /* 40px = vertical padding */

  padding: calc(${Size.HEADER_HEIGHT}px + 20px) 20px 20px;
  margin-left: ${Size.SIDE_BAR_WIDTH}px;
`

const BreadcrumbContainer = styled.div`
  display: inline-flex;
  margin: 20px;
  font-size: 1.5rem;
`

const Layout: FC<Props> = ({ children, isShowBreadcrumb, isShowFooter }) => (
  <MainContent>
    {isShowBreadcrumb && (
      <BreadcrumbContainer>
        <Breadcrumb />
      </BreadcrumbContainer>
    )}
    {children}
    {isShowFooter && <PageFooter />}
  </MainContent>
)

export default Layout
