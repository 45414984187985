import { atom, selector } from 'recoil'
import type Company from 'models/Company'

export const logoUrlState = atom<string>({
  key: 'company/logoUrl',
  default: '',
})

export const companyState = atom<Company | null>({
  key: 'company',
  default: null,
})

export const isOperatorSelectorState = selector({
  key: 'isOperatorSelectorState',
  get: ({ get }) => {
    const company = get(companyState)
    if (!company) {
      return false
    }
    // TODO: クライアントから書き込み可能なコレクションなので、セキュリティ強度が低い
    // 運営会社しか書き込めないコレクションから運営会社のidを取得し、判定するようにする
    // const operatorCompanyIds = ['operatorCompanyId']
    // return operatorCompanyIds.includes(company.id)
    return !!company?.isOperator
  },
})
