export const LINK_URLS = {
  aboutCompany: 'https://leaplace.jp/about#company',
  contact: 'https://forms.gle/W4rYqrveQqsRiqM98',
  termsOfService: 'https://leaplace.jp/terms-of-service',
  privacyPolicy: 'https://leaplace.jp/privacy-policy',
  clientHelp:
    'https://re-hatch.notion.site/LEAPLACE-54c75fe9a1874db29cc700a0929f1d36',
  shortHelp: 'https://re-hatch.notion.site/f90bf1070f6a483aa7be492b32b0f1c6',
  shortGuideLine:
    'https://re-hatch.notion.site/b57fedea47f7425bac4179465557095d',
  shortTips: 'https://re-hatch.notion.site/2d94611e979b4cd2aaf0c6da0e7ff1e0',
  informalDecision: 'https://forms.gle/a7kPCJe6Gz6bds196',
}
