import { Record } from 'immutable'

const SideBarItemRecord = Record({
  id: '',
  title: '',
  iconName: '',
  path: '',
  isExternal: false,
})

class SideBarItem extends SideBarItemRecord {}

export default SideBarItem
