import type { FC } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

type Props = {
  title?: string
  description?: string
  image?: string
}

const SITE_NAME = 'Company | LEAPLACE企業管理画面'
const SITE_DESCRIPTION = 'LEAPLACE企業管理画面'
const SITE_IMAGE_PATH = 'onepair-logo.svg?url'
const SITE_PROD_URL = ''

const getPageTitle = (title: string, path: string): string => {
  if (path === '/') {
    return title
  }
  return `${title} | LEAPLACE企業管理画面`
}

export const HeadContents: FC<Props> = (props) => {
  const router = useRouter()

  const title = props.title || SITE_NAME
  const description = props.description || SITE_DESCRIPTION
  const image = props.image || SITE_IMAGE_PATH

  const pageTitle = getPageTitle(title, router?.asPath)
  const pageUrl = `${SITE_PROD_URL}${router?.asPath}`
  const imageUrl = `${SITE_PROD_URL}${image}`

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta
        name="robots"
        content="noindex , nofollow"
      />
      <meta
        name="description"
        content={description}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={pageTitle}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:url"
        content={pageUrl}
      />
      <meta
        property="og:image"
        content={imageUrl}
      />
      <meta
        property="og:site_name"
        content={SITE_NAME}
      />
      <meta
        property="og:locale"
        content="ja_JP"
      />
      <link
        rel="manifest"
        href="/static/manifest.webmanifest"
      />
      <link
        rel="shortcut icon"
        href="/static/images/icons/icon-48x48.png"
      />
    </Head>
  )
}
