import { Record } from 'immutable'

const BreadcrumbItemRecord = Record({
  title: '',
  route: '',
})

class BreadcrumbItem extends BreadcrumbItemRecord {}

export default BreadcrumbItem
