// Define what props.theme will look like
/**
 * TODO: CSS Variables に移行すること
 */
const theme = {
  main: '#cc4253',
  sub: '#868e96',
  accent: 'rebeccapurple',
  contentWidth: 76,
  maxWidth: 128,
  mobileWidth: '600px',
  fontSize: 1.4,
  span: 4,
}

/**
 * TODO: CSS Variables に移行すること
 */
export const colors = {
  baseRed: '#cc4253',
  activeRed: '#d81919',
  baseBlack: '#1d1d1d',
  disabledGray: '#9f9f9f',
  baseGreen: '#2ea08a',
  baseGray: '#f8f8f8',
  lightGray: '#eeeeee',
}

export default theme
