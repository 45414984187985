export const errorCodes = {
  alreadyRegisteredEmail: 'alreadyRegisteredEmail',
  invalidArgument: 'invalidArgument',
  invalidEmail: 'invalidEmail',
  lock: 'lock',
  registrationAceLimit: 'registrationAceLimit',
  notEnoughAuthority: 'notEnoughAuthority',
  npError: 'npError', // NP掛払い専用エラー
  unexpected: 'unexpected',
  alreadyOfferAce: 'alreadyOfferAce',
  alreadyMatched: 'alreadyMatched',
  attachedRecruitmentExists: 'attachedRecruitmentExists',
  attachedAceIsNotAvailable: 'attachedAceIsNotAvailable',
} as const

const npErrorCodes = {
  // NP掛け払いAPIのエラーコード
  E1999507: 'E1999507', // 郵便番号・住所不一致エラー
  E1999508: 'E1999508', // 購入企業非存在・削除済みエラー
  E1999521: 'E1999521', // 購入企業審査未完了エラー
  E1999556: 'E1999556', // 取引登録不可エラー

  // 独自定義エラーコード
  judgementNG: 'judgementNG', // 総合判断NG
  overAuthoriFacilityAmountLimit: 'overAuthoriFacilityAmountLimit', // 金額超過NG
  unpaidPastRequest: 'unpaidPastRequest', // 未払いNG
} as const

export default class AppError extends Error {
  code: string

  /**
   * 2022年6月時点でNP掛払いは削除中のため、npErrorsの型定義は再導入時に対応する
   */
  constructor(code: string, npErrors?: any) {
    // npErrorsの型はNP掛払い実装時に定義
    super()
    this.code = code || errorCodes.unexpected

    switch (code) {
      case errorCodes.alreadyRegisteredEmail:
        this.message = '既に同じメールアドレスのアカウントが登録されています。'
        break
      case errorCodes.invalidArgument:
        this.message =
          'パラメータに不備があります。入力内容をご確認の上、再度お試しください。'
        break
      case errorCodes.invalidEmail:
        this.message =
          '無効なメールアドレスです。入力内容をご確認の上、再度お試しください。'
        break
      case errorCodes.lock:
        this.message =
          '別の端末で更新された可能性があります。更新をおこなって再度お試しください。'
        break
      case errorCodes.registrationAceLimit:
        this.message = 'エースの上限数に達しているため登録できません。'
        break
      case errorCodes.notEnoughAuthority:
        this.message = '現在の権限ではこの操作は許可されていません。'
        break
      case errorCodes.alreadyOfferAce:
        this.message = '選択したエースからはすでにオファー済です。'
        break
      case errorCodes.alreadyMatched:
        this.message = '既にマッチング済みです。'
        break
      case errorCodes.npError: {
        // TODO: npErrors は配列で返却されるが, 1つしか格納されていない前提 (複数のデータを操作することになった場合は要変更)
        const errorData = (npErrors || []).length > 0 ? npErrors[0] : null
        if (errorData && errorData.codes) {
          this.message = `内部エラーのため一部リクエストに失敗しました。何度もこのメッセージが表示される場合はお問い合わせください。(${(
            errorData.codes || []
          ).join(', ')})`
          const errorCodes = errorData.codes || []
          switch (true) {
            case errorCodes.includes(npErrorCodes.E1999507):
              this.message = `郵便番号と住所が一致していません。入力内容をご確認の上、再度お試しください。`
              break
            case errorCodes.includes(npErrorCodes.E1999508):
            case errorCodes.includes(npErrorCodes.E1999521):
            case errorCodes.includes(npErrorCodes.E1999556):
              this.message = `ただいまチケットの購入できない状況となっております。このエラーが表示された場合はお問い合わせください。`
              break
            case errorCodes.includes(
              npErrorCodes.overAuthoriFacilityAmountLimit,
            ):
              this.message = `現在月において, チケット購入のための利用上限額を上回るため購入ができません。`
              break
            case errorCodes.includes(npErrorCodes.unpaidPastRequest):
              this.message = `未払いの請求があるため、購入に失敗しました。`
              break
            case errorCodes.includes(npErrorCodes.judgementNG):
              this.message = `取引審査の結果により購入に失敗しました。このエラーが発生した場合はお問い合わせください。`
              break
            default:
              break
          }
        } else {
          this.message = `内部エラーのため一部リクエストに失敗しました。何度もこのメッセージが表示される場合はお問い合わせください。`
        }
        break
      }
      case errorCodes.unexpected:
        this.message = 'エラーが発生しました。再度お試しください。'
        break
      case errorCodes.attachedRecruitmentExists:
        this.message = `このエースは募集ポジションが紐づいているので、停止できません。「掲載を編集する」から紐付けを外してください。`
        break
      case errorCodes.attachedAceIsNotAvailable:
        this.message = `利用休止中のエースに募集を紐付けることはできません`
        break
      default:
        break
    }
  }
}

/**
 * MEMO:
 * 収集の運用は停止中のためコメントアウト
 * https://console.firebase.google.com/u/0/project/onepair-crt-debug/firestore/rules
 */
// type ErrorData = {
//   name: string | null
//   message: string | null
//   page: string | null
//   createdAt: Date
//   userId?: string | null
// }
//
// export const sendErrorLog = async (error: AppError | Error) => {
//   if (!error) {
//     return
//   }
//   const crtFirestore = FirebaseManager.crtFirestore
//   const crtAuth = FirebaseManager.crtAuth
//
//   const errorData: ErrorData = {
//     name: error.name || null,
//     message: error.message || null,
//     page: window.location.href || null,
//     createdAt: new Date(),
//   }
//   if (crtAuth.currentUser) {
//     errorData.userId = crtAuth.currentUser.uid || null
//   }
//
//   return crtFirestore.collection('errorLogs').doc().set(errorData)
// }
