import 'firebase/firestore'
import firebase from 'firebase/app'
import FirebaseManager, {
  type FireStorageRef,
  type Firestore,
} from 'utils/FirebaseManager'

class Repository {
  mainFirestore: Firestore
  crtFirestore: Firestore
  mainStorageRef: FireStorageRef
  fieldValue

  constructor() {
    this.mainFirestore = FirebaseManager.mainFirestore
    this.crtFirestore = FirebaseManager.crtFirestore
    this.mainStorageRef = FirebaseManager.mainStorageRef
    this.fieldValue = firebase.firestore.FieldValue
  }
}

export default Repository
